<template>
  <div class="apply">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">信贷产品申请</div>
    <div class="header">
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon1.png" alt="">
            </div>
            <div class="p">企业信贷需求信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon2.png" alt="">
            </div>
            <div class="p">企业基本信息</div>
        </div>
        <div class="step">
            <div class="icon activebg">
                <img src="../../assets/img/applyicon3.png" alt="">
            </div>
            <div class="p active">企业注册登记信息</div>
        </div>
        <div class="step">
            <div class="icon" style="padding-top: 1.5vw;">
                <img src="../../assets/img/applyicon4.png" alt="">
            </div>
            <div class="p">企业经营信息</div>
        </div>
        <div class="step">
            <div class="icon">
                <img src="../../assets/img/applyicon5.png" alt="">
            </div>
            <div class="p">完成</div>
        </div>
    </div>
    <p class="title">企业登记信息</p>
    <div class="card">
        <div class="catalog">
            <p>统一社会信用代码<span class="key">*</span></p>
            <input type="text" v-model="requestData.uniscid" disabled maxlength="30">
        </div>        
        <div class="catalog">
            <p>组织机构代码</p>
            <input type="text" v-model="requestData.orgInstitutionCode" maxlength="60" placeholder="输入组织机构代码">
        </div>
        <div class="catalog">
            <p>国税税务登记证号</p>
            <input type="text" v-model="requestData.nationalTaxRegCertiNo" maxlength="60" placeholder="输入国税税务登记证号">
        </div>
        <div class="catalog">
            <p>地税税务登记证号</p>
            <input type="text" v-model="requestData.localTaxRegCertiNo" maxlength="60" placeholder="输入地税税务登记证号">
        </div>
        <div class="catalog">
            <p>用电客户编号</p>
            <input type="text" v-model="requestData.useElectroAccount" maxlength="60" placeholder="输入用电客户编号">
        </div>
        <div class="catalog">
            <p>用水户口</p>
            <input type="text" v-model="requestData.useWaterAccount" maxlength="50" placeholder="输入用水户口">
        </div>
        <div class="catalog">
            <p>主要结算银行</p>
            <input type="text" v-model="requestData.principalAccountBank" maxlength="30" placeholder="输入主要结算银行">
        </div>
        <div class="catalog">
            <p>基本户开户行</p>
            <input type="text" v-model="requestData.basicAccountOpenBank" maxlength="30" placeholder="输入基本户开户行">
        </div>
        <div class="catalog">
            <p>企业海关注册号</p>
            <input type="text" v-model="requestData.enterCustomsRegNum" maxlength="60" placeholder="输入企业海关注册号">
        </div>
        <div class="catalog">
            <p>排放污染物许可证编号</p>
            <input type="text" v-model="requestData.pollDischPermitNum" maxlength="60" placeholder="输入排放污染物许可证编号">
        </div>
        <div class="catalog">
            <p>危险化学品生产经营许可证证书号</p>
            <input type="text" v-model="requestData.hazardousChemProduBusiLiceNo" maxlength="60" placeholder="输入危险化学品生产经营许可证证书号">
        </div>  
    </div>
    <div style="text-align: center;padding: 4vw 0;">
        <div class="next" @click="goBack">上一步</div>
        <div class="next" @click="nextStep()">下一步</div>
    </div>
    
  </div>
</template>
<script>
import { Toast } from 'vant';
import { ref,reactive,toRaw,toRefs } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { extendJson } from '../../api/axios';
export default {
   setup() {
        let router = useRouter();
        let store = useStore();
        const requestData = ref({
            uniscid: '',
            orgInstitutionCode: '',
            nationalTaxRegCertiNo: '',
            localTaxRegCertiNo: '',
            useElectroAccount: '',
            useWaterAccount: '',
            principalAccountBank: '',
            basicAccountOpenBank: '',
            enterCustomsRegNum: '',
            pollDischPermitNum: '',
            hazardousChemProduBusiLiceNo: ''
        });
        if(store.state.requireInfo) {
            let requireInfo = store.state.requireInfo.enterRegisterInfo;
            requestData.value = extendJson(toRaw(requestData.value),requireInfo);
            requestData.value = extendJson(toRaw(requestData.value),store.state.saveFrontRequirement);
        }else {
            router.push({ name:'home',replace:true});
        }
        const nextStep= () => {
            if(!requestData.value.uniscid) {
                Toast('请先填写统一社会信用代码！')
                return;
            }

            const sum=extendJson(toRaw(store.state.saveFrontRequirement),toRaw(requestData.value));
            store.dispatch('setRequestData',sum);
            console.log(sum,'applyC');
            router.push({
                name:'applyD',
                replace: true
            })
        };
        const goBack=() => {
            const sum=extendJson(toRaw(store.state.saveFrontRequirement),toRaw(requestData.value));
            store.dispatch('setRequestData',sum);
            router.push({ name:'applyB',replace:true})
        };
        return {
            requestData,
            nextStep,
            goBack
        };
  },
}
</script>
<style lang="less" scoped>
.apply {
    padding-top: 12.5vw;
.header {
    margin: 4vw;
    background-color: #fff;
    border-radius: 1.5vw;
    padding: 4vw;
    display: flex;
    justify-content: space-between;
    .step {
        width: 13vw;
        .icon {
            width: 100%;
            height: 13vw;
            background-color: #cccccc;
            border-radius: 50%;
            text-align: center;
            line-height: 13vw;
            box-sizing: border-box;
            img {
                transform: scale(0.5);
            }
        }
        .p {
            color: #666666;
            font-size: 3.1vw;
            text-align: center;
            margin-top: 2vw;
        }
        .activebg {
            background-color: #ff9900;
        }
        .active {
            color: #ff9900;
        }
    }
}
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    
    .card {
        padding: 2vw 4vw;
        .headline {
            background-color: #fdf8ed;
            color: #ff9900;
            font-size: 4vw;
            padding: 2vw;
            border-radius: 1.5vw;
        }
        .catalog {
            display: flex;
            align-items: center;
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;
                // padding: 3vw 0;
            }
            select,input,textarea {
                background-color: #f4f8fe;
                border: 0;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
                margin-left: 2vw;
            }

        }
        
    }
    .next {
        display: inline-block;
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 4vw;
        padding: 1.5vw 5vw;
        border-radius: 5vw;
        margin-left: 4vw;
    }
    
}
</style>